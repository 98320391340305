<template>
  <div class="how-it-works" :class="{ mobile: isPhone, 'l-webscreens': $route.name.includes('webscreens') }">
    <Hero heading="Charging with Electrify America">
      <template slot="image">
        <picture>
          <source
            srcset="
              @/assets/images/WhatToExpect/what-to-expect-hero-desktop.png,
              @/assets/images/WhatToExpect/what-to-expect-hero-desktop@2x.png 2x
            "
            media="(min-width: 750px)"
            alt="Electrify America charging stations"
          />
          <source
            alt="Electrify America charging stations"
            srcset="
              @/assets/images/WhatToExpect/what-to-expect-hero.png,
              @/assets/images/WhatToExpect/what-to-expect-hero@2x.png 2x
            "
          />
          <img src="@/assets/images/WhatToExpect/what-to-expect-hero.png" alt="Electrify America charging stations" />
        </picture>
      </template>
    </Hero>
    <section class="hero-body">
      <div class="container">
        <div>
          <h2>EV Charging That Meets Your Needs</h2>
          <section>
            <p>
              Our <strong>Direct Current (DC) chargers</strong> offer fast charging speeds (Ultra-Fast 150 kW and
              Hyper-Fast 350 kW) —letting you charge in as little as 30 minutes! Here’s what to expect when you charge
              with the largest open Hyper-Fast charging network in the United States.
            </p>
            <router-link class="button-link" :to="{ name: 'getting-started-en' }"
              >learn the basics of electric vehicle charging<arrow class="arrow"></arrow
            ></router-link>
          </section>
        </div>
      </div>
    </section>
    <section class="feature">
      <picture>
        <source
          srcset="
            @/assets/images/WhatToExpect/charger-speeds-desktop.png,
            @/assets/images/WhatToExpect/charger-speeds-desktop@2x.png 2x
          "
          media="(min-width: 750px)"
        />
        <source
          srcset="
            @/assets/images/WhatToExpect/charger-speeds-mobile.png,
            @/assets/images/WhatToExpect/charger-speeds-mobile@2x.png 2x
          "
        />
        <img
          loading="lazy"
          src="@/assets/images/WhatToExpect/charger-speeds-mobile.png"
          alt="Electrify America charging display portraying the teal Ultra-Fast and blue CHAdeMO charging labels"
          aria-labelledby="image-description"
        />
      </picture>
      <div>
        <p id="image-description" class="img-description">
          This image displays a diagram that labels the standard components of the Electrify America charger. The labels
          include: charger number (top left), connector type (bottom left), max charge speed (bottom right), membership
          payment (top right), and customer support (middle).
        </p>
      </div>

      <Card headlineLevel="h2" heading="Charger Labels" :isReverse="isPhone" :maxWidth="1000">
        <template #image>
          <img
            loading="lazy"
            v-if="isPhone"
            src="@/assets/images/WhatToExpect/image-labels-3-d-mobile.png"
            alt="Blue CHAdeMO label, Teal Ultra-Fast CCS label, Green Hyper-Fast CCS label"
          />
          <img
            loading="lazy"
            v-else
            style="max-width: 554px"
            src="@/assets/images/WhatToExpect/image-labels-3-d-desktop.png"
            alt="Blue CHAdeMO label, Teal Ultra-Fast CCS label, Green Hyper-Fast CCS label"
          />
        </template>
        <p>Our charger labels were created with help from drivers like you!</p>
        <p>
          At the top of each label, you’ll find connector icons that match your vehicle’s charging port. At the bottom,
          lightning bolt icons signify the charger’s power—the higher the kilowatt (kW), the greater the power.
        </p>
        <p>Our charging stations offer three connectors:</p>
        <ul>
          <li>Green <strong>Hyper-Fast</strong> labels indicate CCS connectors delivering up to 350 kW.</li>
          <li>Teal <strong> Ultra-Fast</strong> labels indicate CCS connectors delivering up to 150 kW.</li>
          <li>Blue <strong>CHAdeMO</strong> labels indicate CHAdeMO connectors delivering up to 50 kW.</li>
        </ul>
        <div class="tip">
          <img loading="lazy" src="@/assets/images/WhatToExpect/icon-star.png" alt="" />
          <p>
            <strong>Tip:</strong> Not all EVs can charge at a connector’s maximum power level. For example, a Hyper-Fast
            label means the charger offers up to 350 kW for a CCS-compatible EV. If your car is not capable of a 350 kW
            maximum charge, the charger automatically supplies the highest power level your vehicle can handle. Check
            your vehicle’s manufacturer manual to learn your car’s capacity.
          </p>
        </div>
      </Card>
    </section>
    <section class="balance">
      <Card headlineLevel="h2" heading="Balanced Charging" :isReverse="true" :maxWidth="1000">
        <template #image>
          <img
            loading="lazy"
            class="image"
            v-if="isPhone"
            src="@/assets/images/WhatToExpect/image-balanced.png"
            alt="Depiction of power cabinet sending power to two side-by-side balanced chargers"
          />
          <img
            loading="lazy"
            class="image"
            v-else
            src="@/assets/images/WhatToExpect/image-balanced-desktop.png"
            alt="Depiction of power cabinet sending power to two side-by-side balanced chargers"
          />
        </template>
        <img
          loading="lazy"
          style="order: -1"
          class="bolt"
          src="../../assets/images/WhatToExpect/image-bolts.webp"
          alt=""
        />
        <p>
          At Electrify America stations, you may notice the word “Balanced” on a charger’s labels or screen. Balanced
          chargers are typically located side-by-side and share a power cabinet. When a charger is Balanced, it can
          supply Ultra-Fast speeds up to 150 kW for vehicles capable of accepting this much power and can provide
          Hyper-Fast speeds up to 350 kW to capable vehicles when the adjacent charger is unused.
        </p>
        <strong>Balanced chargers are labeled with the tag below:</strong>
        <img
          loading="lazy"
          class="hyper-fast"
          src="@/assets/images/WhatToExpect/power-sharing.png"
          alt="Green, Hyper-fast, CCS balanced power sharing charger label up to 350 kW"
        />
      </Card>
    </section>

    <HeroWithContentOverlay :overlayBG="false" :darkBG="false" fullWidthMobile>
      <template #image>
        <picture>
          <source
            srcset="@/assets/images/WhatToExpect/charging-station-hyper-fast-desktop.png"
            media="(min-width: 850px)"
          />
          <source srcset="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.png" />
          <img
            loading="lazy"
            src="@/assets/images/WhatToExpect/charging-station-hyper-fast-mobile.png"
            alt="Hyper fast charging station HMI display with mobile pay instructions, station ID, and Customer Assistance number 1-833-632-2778"
          />
        </picture>
      </template>

      <router-link class="button-link" :to="{ name: 'charging-your-ev-en' }">
        How To<span class="sr-only">charge at Electrify America stations</span><arrow class="arrow"></arrow>
      </router-link>
      <h2>How To Charge at Electrify America Stations</h2>
      <p>
        Starting a charge at our stations can be done from the charger, our app, Plug&Charge, or through your vehicle
        using Apple CarPlay or Android Auto.
      </p>
    </HeroWithContentOverlay>
  </div>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow_dark.svg';
import Hero from '@/components/Hero/Hero.vue';
import Card from '@/components/Card/Card.vue';
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';

export default {
  name: 'how-it-works',
  components: {
    Arrow,
    Hero,
    Card,
    HeroWithContentOverlay,
  },
  metaInfo: {
    title: 'Electrify America Charging Speed | EV Fast Charger',
    meta: [
      {
        name: 'description',
        content: `With Electrify America's DC fast chargers, you can charge in as little as 30 minutes! Learn more about EV charging speeds at our fast charging stations.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/what-to-expect/' }],
  },
  data() {
    return {
      num: null,
      body: null,
    };
  },
  computed: {
    isPhone() {
      return this.$resize && this.$mq.below(749);
    },
  },
};
</script>

<style scoped lang="scss">
.how-it-works {
  //  Default Page Styling
  p {
    letter-spacing: 0.8px;
    font-weight: $fw-light;
    margin-bottom: 24px;
    margin-top: 0;
  }

  h2,
  h4 {
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
  }

  h2 {
    &.light {
      font-weight: $fw-regular;
    }
  }

  img {
    width: 100%;
  }

  // Utility Classes
  .container {
    padding: 0 20px;
    max-width: 1200px;
    margin: 0 auto;
  }

  .flex {
    display: flex;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  // Section Grouping

  .hero-body {
    background-color: $c-secondary-background;
    @media (min-width: 1130px) {
      padding: 100px 0;
    }
  }

  .hero-with-overlay {
    margin: 112px auto;
    width: 90%;
    @media (max-width: 750px) {
      margin: 56px auto;
      max-height: 382px;
    }

    h2 {
      margin: 16px 0;
      font-size: 32px;
      line-height: 38px;
      margin-top: 0;
      font-weight: 400;

      @media (min-width: 750px) {
        font-size: 48px;
        line-height: 50px;
      }
    }
    p {
      margin: 16px 0;
      @media (max-width: 750px) {
        padding-right: 20px;
        margin-bottom: 0;
      }
    }
    ::v-deep &__image-container {
      width: 100%;
      margin: 0 auto;
    }
  }

  .hero-body {
    padding: 60px 0 56px;
    img {
      margin-top: -220px;
      border-radius: 8px;
    }
    h2 {
      margin-bottom: 24px;
      @media (min-width: 750px) {
        font-size: 32px;
        margin: 0;
        width: 35%;
      }
    }

    @media (min-width: 750px) {
      .container div {
        padding: 112px 0;
        max-width: 960px;
        display: flex;
        margin: 0 auto;
        section {
          padding-left: 20px;
          width: 60%;
        }
      }
    }
  }

  // Sections

  .feature {
    padding: 0;
    div {
      width: 100%;
      display: flex;
      justify-content: center;
      .img-description {
        font-size: 12px;
        padding: 15px;
      }
    }

    .container {
      max-width: 1000px;
      padding-top: 56px;
    }

    ul {
      margin-left: 16px;
      margin-bottom: 16px;
      color: #000;
      font-weight: $fw-regular;
      strong {
        font-weight: 600;
      }
    }

    h2 {
      margin-bottom: 24px;
      font-size: 24px;
    }
    h2,
    p {
      max-width: 800px;
      margin-bottom: 24px;
    }

    @media (min-width: 900px) {
      padding: 0px;
      .container {
        display: flex;
        div {
          order: 2;
        }
        img {
          order: 1;
        }
      }
    }
    @media (min-width: 750px) {
      .container {
        padding-top: 112px;
        padding-bottom: 56px;
      }
    }
  }

  .balance {
    border-radius: 8px;
    background-color: $c-secondary-background;
    .bolt {
      width: 99px;
      height: auto;
      margin-top: 20px;
      @media (min-width: 750px) {
        margin-bottom: 10px;
      }
    }
    .flex {
      @media (max-width: 800px) {
        flex-direction: column;
      }
      .image {
        flex: 1;
      }
    }
    strong {
      margin-bottom: 12px;
    }
    @media (min-width: 750px) {
      max-width: 1200px;
      margin: 0 auto;
      margin-bottom: 112px;
      .hyper-fast {
        margin-bottom: 0;
        max-width: 445px;
      }
      strong {
        letter-spacing: 0.8px;
        margin-bottom: 12px;
      }

      .image {
        max-width: 392px;
      }
    }
  }
}
</style>
